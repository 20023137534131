import { Modal } from '@opsdti-global-component-library/amgen-design-system';
import React, { lazy, useEffect, useState } from 'react';
import './footer-form.scss';
import { lazyImport } from '../../utils/lazy-import';
import SensingLogoSvg from '../svgs/sensing-logo-svg';

const FooterForm = lazy(() => lazyImport('@amgen/rtsensing-footer-form'));

interface FeedbackModalProps {
  title?: string;
  opened: boolean;
  onFeedbackModalClosed: () => void;
  feedbackSource: string;
}

const FeedbackModal = (props: FeedbackModalProps) => {
  const { opened, onFeedbackModalClosed, feedbackSource } = props;
  const [title, setTitle] = useState<string>('');
  useEffect(() => {
    if (feedbackSource === 'FEEDBACK') {
      setTitle('Feedback');
    } else if (feedbackSource === 'CONTACT') {
      setTitle('Contact Us');
    } else if (feedbackSource === 'ISSUE') {
      setTitle('Report Inaccuracy');
    } else {
      setTitle('');
    }
  }, [feedbackSource])

  useEffect(() => {
    if (opened) {
      const headerElement = document.querySelector('.request-modal-header');
      if (headerElement && headerElement.parentElement) {
        headerElement.parentElement.classList.add('footer-modal-header');
      }
    }
  }, [opened]);

  return (
    <Modal open={opened} onCancel={onFeedbackModalClosed} title={
      <span className="request-modal-header">
        <SensingLogoSvg className='' />
        <div className="request-modal-title">{title}</div>
      </span>}
      className="feedback-modal" width={600} fixedHeight={true}>

      <FooterForm modelType={feedbackSource} modelClose={onFeedbackModalClosed} />
    </Modal>
  );
};

export default FeedbackModal;
