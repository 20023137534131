import {
  tss,
  breakpoints,
} from '@opsdti-global-component-library/amgen-design-system';

export const useStyles = tss.create({
  mobileNavButton: {
    alignItems: 'center',
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    left: 16,
    outline: 0,
    position: 'absolute',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
});
