import React from 'react';
import { createPortal } from 'react-dom';

import { CommentIcon, InfoIcon, EnvelopeIcon, Text } from '@opsdti-global-component-library/amgen-design-system';
import SectionButtonLink from 'desktop-side-nav/section-button-link';

import './index.scss';
import FadeTransition from 'components/fade-transition';
import { NavLink } from 'types';

interface FooterProps {
  activeRouteData: NavLink;
  toggleFeedbackModal: (type: 'CONTACT' | 'FEEDBACK' | 'ISSUE') => void;
}

const Footer = (props: FooterProps) => {
  const { activeRouteData, toggleFeedbackModal } = props;
  const contentRoot = document.getElementsByTagName('main')[0];

  const getConfidentialityClass = () => {
    return (
      activeRouteData.confidentiality &&
      `rtsensing-confidential-label--${activeRouteData.confidentiality.type ?? 'confidential'
      }`
    );
  };

  if (!contentRoot) {
    return null;
  }

  return createPortal(
    <div className="rtsensing-footer-wrapper">
      <FadeTransition itemKey={activeRouteData.label}>
        <h3
          className={`rtsensing-confidential-label ${getConfidentialityClass()}`}
        >
          {activeRouteData.confidentiality &&
            activeRouteData.confidentiality.label.toUpperCase()}
        </h3>
      </FadeTransition>

      <div className="rtsensing-footer-link-group">
        <SectionButtonLink
          className="conf-link-nav-icon"
          onClick={event => toggleFeedbackModal('ISSUE')}
        >
          <InfoIcon height={14} width={14} />
          <Text style={{ fontWeight: 400 }}>Report Inaccuracy</Text>
        </SectionButtonLink>
        <SectionButtonLink
          className="conf-link-nav-icon"
          onClick={event => toggleFeedbackModal('FEEDBACK')}
        >
          <CommentIcon height={14} width={14} />
          <Text style={{ fontWeight: 400 }}>Feedback</Text>
        </SectionButtonLink>
        <SectionButtonLink
          className="conf-link-nav-icon"
          onClick={event => toggleFeedbackModal('CONTACT')}
        >
          <EnvelopeIcon height={14} width={14} />
          <Text style={{ fontWeight: 400 }}>Contact Us</Text>
        </SectionButtonLink>
      </div>
    </div>,
    contentRoot,
  );
};

export default Footer;
